.deco_hanja_content {
	position: absolute;
	top: 80px; /*Set top value to HeightOfTopFrameDiv*/
	left: 0;
	right: 0;
	margin-left: 30px;
	margin-right: 30px;
	bottom: 15px; /*Set bottom value to HeightOfBottomFrameDiv*/
	overflow: auto;
	font-size: 18px;
	color: black;
	width: auto;
}
