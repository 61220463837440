.pagination .page-item .page-link-custom {
  margin: 0 2px; 
  border-radius: 100%;
  color: #03a9f4;
  border-color: #e0e0e0;
  position: relative;
  z-index: 2;
  transition: all ease .5s;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  border: 0;
  padding: 6px; 
}