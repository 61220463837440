.arrow_box {
	position: relative;
	/*background: #88b7d5;*/
	border: 2px solid #2185d0;
	color: #2185d0;
	border-radius: 5px;
	line-height: 18px;
	text-align: center;
}
.arrow_box:after,
.arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #ffffff;
	border-width: 4px;
	margin-top: -4px;
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #2185d0;
	border-width: 6px;
	margin-top: -6px;
}
