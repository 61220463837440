/*
.ms-navbar .navbar-header, .ms-lead-navbar .navbar-header {
  display: flex!important;
  height: 50px;
}

.ms-navbar .navbar-header, .ms-lead-navbar .navbar-header {
  display: flex!important;
  height: 50px;
}
*/

.custom-header {
	display: block !important;
	height: auto !important;
}

.custom-collapse {
	justify-content: flex-end !important;
	margin-right: 50px !important;
}

.custom-menu {
	display: block !important;
}

.custom-h4 {
	margin: auto !important;
	color: #fff;
}

.s-title,
.s-title:hover,
.s-title:active,
.s-title:focus {
	color: #ffffff;
}

.cd-breadcrumb,
.cd-multi-steps {
	width: 100%;
	max-width: 100%;
	background-color: transparent;
	border-radius: 0.25em;
	margin: 0px;
}

.ms-navbar,
.ms-lead-navbar {
	margin-bottom: 0px !important;
	z-index: 1000 !important;
}

.breadcrumb-bar {
	background-color: #eeeeee;
}

.cd-breadcrumb li,
.cd-multi-steps li {
	font-size: 10pt;
	margin-top: 5px;
	margin-bottom: 5px;
}

.cd-breadcrumb li > *,
.cd-multi-steps li > * {
	font-size: 10pt;
}

@media only screen and (min-width: 768px) {
	.cd-multi-steps.text-top li,
	.cd-multi-steps.text-bottom li {
		width: 120px;
		text-align: center;
	}
	.cd-multi-steps.text-bottom.count li > * {
		padding-top: 40px;
	}
}

.sub_title {
	margin: 5px 0px 0px 0px !important;
}

.deskContent {
	display: block;
}
.mobileContent {
	display: none;
}
.nav-contents {
	display: block;
}

@media all and (max-width: 720px) {
	.deskContent {
		display: none;
	}
	.mobileContent {
		display: block;
	}
}

@media all and (max-width: 1024px) {
	.nav-contents {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.ms-navbar.navbar .container {
		width: 100%;
		height: 100%;
	}
}

.ms-slidebar .ms-slidebar-header .ms-slidebar-login a {
	color: #fff;
	text-align: center;
	display: table-cell;
	width: auto !important;
	padding: 10px 20px;
}
