.top-menu {
	min-height: 50px !important;
	background-color: #fff;
	position: relative;
	z-index: 2;
}

.deskContent {
	display: block;
}
.mobileContent {
	display: none;
}

.custom > a:hover {
	color: #424242 !important;
}

.custom > a::before {
	background-color: #fff !important;
}

/*
.nav-item>a:hover::before,
.nav-item>a:focus:active {
  height: 4px !important;
}

.nav-item>a.active:hover::before,
.nav-item>a.active:focus:active {
  height: 4px !important;
}
*/

@media all and (max-width: 720px) {
	.deskContent {
		display: none;
	}
	.mobileContent {
		display: block;
	}
}

/*
.nav-tabs-custom {
  height: 45px;
  background-color: #f5f5f5!important
}

.nav-tabs-custom  .active>a,
.nav-tabs-custom  .active>a:hover,
.nav-tabs-custom  .active>a:focus {
  background-color: #9c18b3!important;
  color: white!important;
}

.nav-tabs-custom  {
   border: 0px solid #f5f5f5;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.nav-tabs-custom  li a {
  color: dimgray!important;
  line-height: 3.0em;
}

.etc-menu {
  height: 45px;
  font-size: 10pt;
}

.etc-menu li.nav-item{
  font-size: 10pt;
  line-height: 3.3em;
}


.deskContent {display:block;}
.mobileContent {display:none;}

@media all and (max-width: 720px) {
  .deskContent {display:none;}
  .mobileContent {display:block;}
}
*/

.left-menu-item > label {
	margin: 0;
	padding: 0.5rem 0.5rem 0.5rem 0;
}

.left-menu-item > label:hover {
	cursor: pointer;
}

.flex-row {
	display: flex;
	/* gap: 5px;
	width: 25px;
	height: 25px;
	margin: 0 5px; */
	vertical-align: middle;
	/* background-size: contain; */
	background-repeat: no-repeat;
	cursor: pointer;
	justify-content: flex-end;
	align-items: center;
	bottom: 0px;
}

.menu-item {
	color: rgb(0, 0, 0);
}

.menu-item:hover {
	color: red;
}

.menu-item.active {
	color: red;
}

.menu-item2 {
	color: rgb(0, 0, 0);
}

.menu-item2:hover {
	color: rgb(0, 110, 255);
}

.menu-item2.active {
	color: rgb(0, 110, 255);
}

.menu-item3 {
	color: rgb(0, 0, 0);
}

.menu-item3:hover {
	color: rgb(127, 4, 138);
}

.menu-item3.active {
	color: rgb(127, 4, 138);
}

.menu-item4 {
	position: relative;
	top: 0.4rem;
	/* width: 20px;
	height: 20px; */
	background-image: url('/assets/imgs/home/X_logo.png');
	background-size: 20px;
	background-repeat: no-repeat;
	/* vertical-align: middle; */
	cursor: pointer;
}

.menu-item4:hover {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
}

.menu-item4.active {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
}

.menu-item5 {
	/* width: 25px;
	height: 25px; */
	background-image: url('/assets/imgs/home/blog.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	/* vertical-align: middle; */
	cursor: pointer;
}

@media (max-width: 1200px) {
	.menu-item5 {
		/* top: 0.1rem !important; */
		background-size: 22px !important;
	}
}

.menu-item5:hover {
	background-image: url('/assets/imgs/home/blog_hover.png');
}

.menu-item5.active {
	background-image: url('/assets/imgs/home/blog_hover.png');
}

.menu-item4-logo {
	position: relative;
	top: 0.4rem;
	/* right: 3px; */
	width: 23px;
	height: 27px;
	box-sizing: border-box;
	background-image: url('/assets/imgs/home/X_logo.png');
	background-size: contain;
	background-repeat: no-repeat;
	vertical-align: bottom;
	cursor: pointer;
}

@media (max-width: 1200px) {
	.menu-item4-logo {
		width: 18px; /* 1200px 이하 화면에서 줄어든 크기 */
		height: 20px;
	}
}

.menu-item4-logo:hover {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
}

.menu-item4-logo.active {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
}
