.logout-custom-ui {
  text-align: center;
  min-width: 330px;

  padding: 20px 0 10px 0;
  background: white;
  border: 1px solid var(--modal-border);
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.2);
  /* color: #fff; */
  border-radius: 20px;
}

.logout-custom-ui > h3 {
  font-weight: 500;
  margin: 10px 0 15px 0;
}

.logout-custom-ui > h3 > i {
  margin-right: 12px;
  color: #29d729;
}

.logout-custom-ui > p {
  margin: 1rem;
}

.logout-custom-ui > p:nth-child(3) {
  margin-bottom: 8px;
}

.logout-custom-ui > div {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-custom-ui > div > button {
  width: 100px;
  height: 38px;
  padding: 0.5rem;
  margin: 10px;
  border-radius: 999px;
  cursor: pointer;
}

.logout-btns {
  margin: 10px 0 0 0;
}

.logout-btns > button:first-child {
  margin: 5px;
  font-size: 14px;
  transition: 0.1s ease-in-out;
}

.logout-btns > button:last-child {
  margin: 5px;
  font-size: 14px;
  /* background-color: var(--modal-button-danger);
  border: 1px solid var(--modal-button-danger); */
  transition: 0.1s ease-in-out;
}
.logout-btns > button:first-child:hover {
  font-weight: 600;
  /* font-size: 15px; */
}

.logout-btns > button:last-child:hover {
  font-weight: 600;
  /* font-size: 15px; */
}

.company-infomation:hover{
  cursor:pointer
}