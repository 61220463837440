.modal-custom {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  z-index: 4000 !important;
}

.modal-dialog-custom {
  position: fixed !important;
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 320px !important;
  height: 100% !important;
  padding: 0 !important;
}

.modal-content-custom {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  border: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.modal-header-custom {
  top: 0;
  right: 0;
  left: 0;
}

.modal-title-custom {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}

@media all and (max-width: 768px) {
  .modal-body-custom-desktop {
    position: absolute !important;
    top: 165px !important;
    bottom: 165px !important;
    width: 100% !important;
    font-weight: 300 !important;
    overflow: auto !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .modal-footer-custom-desktop {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    height: 165px !important;
    width: 100% !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
  }
}

@media all and (min-width: 768px) {
  .modal-body-custom-desktop {
    position: absolute !important;
    top: 80px !important;
    bottom: 100px !important;
    width: 100% !important;
    font-weight: 300 !important;
    overflow: auto !important;
  }

  .modal-footer-custom-desktop {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    height: 100px !important;
    width: 100% !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
  }
}

.underline-custom {
  padding-bottom: 1px;
  border-bottom: 2px solid #000;
  line-height: 1em;
  background-size: 2px 5px;
}

.radio span,
label.radio-inline span {
  display: inline-block !important;
  position: relative !important;
  left: 0px !important;
  top: 0px !important;
  transition-duration: 0s !important;
}

.lqtest-custom-stop-ui {
  text-align: center;
  min-width: 400px;
  padding: 25px 20px 10px 20px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  border: 1px solid var(--modal-border);
  border-radius: 20px;
}

.lqtest-custom-stop-ui > div > button {
  cursor: pointer;
  width: 100px;
  height: 38px;
  border-radius: 999px;
  font-size: 14px;
  /* border: 1px solid #fff; */
  /* color: #fff; */
}

.lqtest-custom-stop-ui > div > button:first-child {
  margin: 10px;
  transition: all 0.1s ease-in-out;
}

.lqtest-custom-stop-ui > div > button:first-child:hover {
  /* transform: scale(1.1); */
  /* font-size: 15px; */
  font-weight: 600;
}

.lqtest-custom-stop-ui > div > button:last-child {
  background: var(--modal-button-danger);
  border: 1px solid var(--modal-button-danger);
  transition: all 0.1s ease-in-out;
}

.lqtest-custom-stop-ui > div > button:last-child:hover {
  /* transform: scale(1.1); */
  /* font-size: 15px; */
  font-weight: 600;
}

.lqtest-custom-ui {
  text-align: center;
  min-width: 320px;
  padding: 25px 20px 10px 20px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
  border: 1px solid #d4d4d5;
  border-radius: 20px;
}

.lqtest-custom-ui > h3 {
  font-weight: 500;
}

.lqtest-custom-ui > button {
  width: 120px;
  padding: 7px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  border-radius: 999px;
  transition: all 0.2s ease-in-out;
}

.lqtest-custom-ui > button:hover {
  font-weight: 500;
  /* transform: scale(1.1); */
  /* font-size: 14px; */
}

.carousel-inner-custom {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.ms-carousel .carousel-control-prev,
.ms-carousel .carousel-control-next {
  position: relative;
}

.ms-carousel .carousel-indicators-tumbs li {
  width: 24px !important;
  height: 24px !important;
  border-radius: 5px;
  vertical-align: middle;
}

.ms-carousel .carousel-indicators-tumbs .custom li.active {
  border-color: #4caf50 !important;
  background-color: #4caf50 !important;
  color: #ffffff !important;
}

.well {
  word-break: break-all;
  text-align: justify;
}

.checked-answer {
  background-color: lightslategrey;
  width: 100%;
  height: 100%;
  color: white;
}
