.recharts-wrapper {
	height: 30px !important;
}

.flex-container {
	display: flex;
	flex-wrap: nowrap;
	overflow: auto;
}
.flex-item {
	flex: 0 0 auto;
	padding-bottom: 10px;
}
