.pricing-table-container {
	background-color: #fff;
	box-shadow: none;
}
.pricing-table-head {
	font-size: 1.4rem;
	text-align: center;
	background-color: #fff;
	margin: 0;
	font-weight: 700;
	padding: 10px;
}
.pricing-table-head.warning {
	border-bottom: 3px solid #ff9800;
}
.table-tx-head {
	font-size: 1.2rem;
	font-weight: 700;
	border-top: 3px solid #333;
}
.table thead.warning {
	border-bottom: 1px solid #ff9800;
}
.table thead.royal {
	border-bottom: 1px solid #9b26ad;
}
.table thead.info {
	border-bottom: 1px solid #1ebdd2;
}
.table thead.success {
	border-bottom: 1px solid #50af55;
}
.table thead.primary {
	border-bottom: 1px solid #1daaf1;
}
.table-tx-body {
	font-size: 1.2rem;
}
.table td,
.table th {
	padding: 0.3rem;
	vertical-align: top;
}
.table tr td,
.table tr th {
	padding: 5px !important;
	border-color: #e0e0e0 !important;
}
.column1 {
	width: 15%;
}
.column2 {
	width: 25%;
}
.column3 {
	width: 28%;
}
.column4 {
	width: 8%;
}
.tb-bg {
	background-color: #fafafa;
}
.td-warning-bg {
	background-color: #f8f5f0;
}
.td-royal-bg {
	background-color: #f6f1ff;
}
.td-info-bg {
	background-color: #e9f4f5;
}
.td-success-bg {
	background-color: #eff9f0;
}
.td-primary-bg {
	background-color: #f8f5f0;
}
.table-bottom {
	border-bottom: 1px solid #999;
}

.btn-c-dark {
	background-color: #3b4c55 !important;
	color: #fff !important;
	border: none;
	border-radius: 0px;
	display: block;
	width: 100%;
	padding: 10px 20px;
	padding: 1rem 2rem;
	transition: all ease 0.3s !important;
	text-transform: uppercase !important;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3) !important;
}

.nav-tabs .nav-link.active {
	border-bottom: 3px solid !important;
}
