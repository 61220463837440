body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif !important;
}
.clear-block::after {
  content: '';
  display: block;
  clear: both;
}

.scrollOff {
  position: fixed;
  overflow: hidden;
  height: 100%;
}

.dp-none {
  display: none !important;
}

.dp-flex {
  display: flex !important;
}
.dp-flex-wrap {
  display: flex !important;
  flex-flow: wrap;
}

.yt-4 {
  margin-top: 0.5rem !important;
}

.ps-relative {
  position: relative !important;
}

.w-10per {
  width: 10% !important;
}

.w-15per {
  width: 15% !important;
}

.w-20per {
  width: 20% !important;
}

.w-25per {
  width: 25% !important;
}

.w-30per {
  width: 30% !important;
}

.w-35per {
  width: 35% !important;
}

.w-40per {
  width: 40% !important;
}

.w-50per {
  width: 50% !important;
}

.w-55per {
  width: 55% !important;
}

.w-60per {
  width: 60% !important;
}

.w-70per {
  width: 70% !important;
}

.w-80per {
  width: 80% !important;
}
.w-90per {
  width: 90% !important;
}
.w-95per {
  width: 95% !important;
}
.w-100per {
  width: 100% !important;
}
.w-110per {
  width: 110% !important;
}
.w-120per {
  width: 120% !important;
}
.w-130per {
  width: 130% !important;
}
.w-140per {
  width: 140% !important;
}
.w-150per {
  width: 150% !important;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}

.mb--50px {
  margin-bottom: -50px !important;
}

.btn-padding-1-2 {
  padding: 1rem 2rem !important;
}

.padding-1_25 {
  padding: 1.25rem !important;
}

.padding-1_70 {
  padding: 1.7rem !important;
}

.text-13-impo {
  font-size: 1.3rem !important;
}

.text-15-impo {
  font-size: 1.5rem !important;
}

.text-17-impo {
  font-size: 1.7rem !important;
}

.input-p-impo {
  padding: 1.3rem 2rem !important;
}

.text-align-center-impo {
  text-align: center !important;
}

.text-align-left-impo {
  text-align: left !important;
}

.text-align-right-impo {
  text-align: right !important;
}

/* Color *************************************************************** /

/* LQ ---------------------------------- */
.lq-color-01 {
  color: #8443a5 !important;
}
.lq-color-02 {
  color: #7f5c98 !important;
}
.lq-color-03 {
  color: #9979ad !important;
}
.lq-color-04 {
  color: #6f71ab !important;
}
.lq-color-05 {
  color: #322d58 !important;
}

.lq-bgcolor-01 {
  background-color: #8443a5 !important;
}
.lq-bgcolor-02 {
  background-color: #7f5c98 !important;
}
.lq-bgcolor-03 {
  background-color: #9979ad !important;
}
.lq-bgcolor-04 {
  background-color: #6f71ab !important;
}
.lq-bgcolor-05 {
  background-color: #322d58 !important;
}

.lq-panel-border-bottom {
  border-bottom: solid 3px #6f71ab !important;
}

.tooltip {
  z-index: 9999 !important;
}

.img-puzzle-result-92 {
  max-width: 92% !important;
  height: auto;
}

.img-puzzle-result-100 {
  max-width: 100% !important;
  height: auto;
}

/* Button *************************************************************** */

.ms-fare-btn {
  background-color: #3b4c55;
  color: #fff;
  border: none;
  border-radius: 0px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  padding: 1rem 2rem;
  transition: all ease 0.3s;
  text-transform: uppercase;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}
.ms-fare-btn:hover,
.ms-subscribre-btn:focus {
  background-color: #3b4c55;
}
.ms-fare-btn:hover,
.ms-subscribre-btn:focus {
  background-color: rgba(0, 0, 0, 0.5);
}
.ms-fare-btn:after {
  font-family: Material-Design-Iconic-Font;
  font-size: 2rem;
  content: '\f1f5';
  margin-left: 10px;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
}
.ms-fare-btn:hover:after,
.ms-fare-btn:focus:after {
  font-size: 2rem;
  content: '\f1f7';
  display: inline-block;
  margin-left: 20px;
}
.ms-fare-btn:after {
  font-family: Material-Design-Iconic-Font;
  font-size: 2rem;
  content: '\f1f5';
  margin-left: 10px;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
}

@import 'styles/custom-natmal.css';
@import 'styles/natmal-helpers.css';

.main-box-roll {
  font-size: 16px !important;
  /*background: linear-gradient(to right, skyblue 5%, green 20%, white)!important;*/
}
.main-box-roll-title {
  font-size: 2em !important;
}
.main-box-roll-desc,
.main-box-roll-desc-nolink {
  font-size: 1em !important;
  line-height: 1.2em !important;
}

.home-menu-items {
  font-size: 1.2em !important;
}

.top-card-menu {
  font-size: 1em !important;
  padding: 0px;
}

.statistic-count {
  font-size: 3em;
  margin-bottom: 12px;
  font-family: 'Roboto';
  color: darkblue;
}

.main-box-desc {
  font-size: 1em !important;
  line-height: 1.5em;
}

.statistic-desc,
.statistic-title {
  font-size: 1em !important;
}

/* xl ************************************************************** */
@media (max-width: 1440px) {
  .main-box-position-dic {
    font-size: 16px !important;
  }
  .main-box-sub-title {
    font-size: 1em !important;
  }
  .main-box-desc {
    font-size: 0.8em !important;
    line-height: 1.2em;
  }
  .statistic-desc,
  .statistic-title {
    font-size: 0.8em !important;
  }
  .statistic-count {
    font-size: 2em;
    margin-bottom: 12px;
    font-family: 'Roboto';
    color: darkblue;
  }
}

/* lg ************************************************************** */
@media (max-width: 1200px) {
  .main-box-position-dic {
    font-size: 14px !important;
  }
  .main-box-sub-title {
    font-size: 1em !important;
  }
  .main-box-eng-title {
    font-size: 0.8em !important;
  }
  .main-box-desc {
    font-size: 0.8em !important;
  }
  .statistic-desc,
  .statistic-title {
    font-size: 0.8em !important;
  }
  .main-box-roll-title {
    font-size: 1.5em !important;
  }
  .main-box-roll-desc,
  .main-box-roll-desc-nolink {
    font-size: 0.8em !important;
    line-height: 1.2em !important;
  }

  .home-menu-items {
    font-size: 1em !important;
  }

  .top-card-menu {
    font-size: 0.85em !important;
    padding: 0px;
  }

  .statistic-count {
    font-size: 2em;
    color: darkblue;
  }
}

/* md ************************************************************** */
@media (max-width: 992px) {
  .main-box-position-dic {
    font-size: 14px !important;
  }
  .main-box-sub-title {
    font-size: 1em !important;
  }
  .main-box-eng-title {
    font-size: 1em !important;
  }
  .main-box-desc {
    font-size: 1em !important;
    line-height: 1.5em;
  }
  .statistic-desc,
  .statistic-title {
    font-size: 1em !important;
  }
}

/* sm ************************************************************** */
@media (max-width: 768px) {
  .w-sm-100per {
    width: 100% !important;
  }
  .main-box-position-dic {
    font-size: 14px !important;
  }
  .main-box-sub-title {
    font-size: 1em !important;
  }
  .top-card-menu {
    font-size: 1em !important;
    padding: 0px;
  }
  .top-card-menu {
    font-size: 1em !important;
    padding: 0px;
  }
  .statistic-count {
    font-size: 2em;
    color: darkblue;
  }
}

/* xs ************************************************************** */
@media (max-width: 576px) {
  .w-xs-100per {
    width: 100% !important;
  }
  .main-box-analysis {
    font-size: 1rem;
  }
  .top-card-menu {
    font-size: 1em !important;
    padding: 0px;
  }
}

/* xxs ************************************************************** */
@media (max-width: 420px) {
  .main-box-position-dic {
    font-size: 14px !important;
  }
  .main-box-sub-title {
    font-size: 1em !important;
  }
  .main-box-eng-title {
    font-size: 0.8em !important;
  }
  .main-box-desc {
    font-size: 0.8em !important;
    line-height: 1.2em;
  }
  .statistic-desc,
  .statistic-title {
    font-size: 0.8em !important;
  }
  .top-card-menu {
    font-size: 1em !important;
    padding: 0px;
  }
}

.ms-collapse-nav li.card ul a:after {
  content: '';
}

.ant-drawer-close {
  color: #cccccc;
}
