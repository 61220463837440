:root {
  --modal-button-danger: #ff4d4f;
  --modal-button-main: #353131;
  --modal-button-hover: rgba(0, 0, 0, 0.6);
  --modal-button-whitebackground: #f5f5f5;
  --modal-border: #d4d4d5;
}

.deskContent {
  display: block;
}
.mobileContent {
  display: none;
}

@media all and (max-width: 720px) {
  .deskContent {
    display: none;
  }
  .mobileContent {
    display: block;
  }
}

.ui.dimmer {
  z-index: 3000 !important;
}

.radio label,
.checkbox label {
  min-height: 0px !important;
}

@media (max-width: 480px) {
  .ms-paper .ms-paper-content-container .ms-paper-content {
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 2em 0px !important;
  }
}

.ms-collapse-nav li.card a.collapsed {
  background-color: #eee;
  color: #424242;
  border-bottom: 1px darkgrey solid;
}

.ms-collapse-nav li.card a.collapsed:hover {
  background-color: #e0a230 !important;
  color: #fff;
}

.ms-collapse-nav li.card ul a:hover {
  background-color: rgba(224, 162, 48, 0.555) !important;
  color: #fff;
}
