.ant-badge-custom {
	box-sizing: border-box;
	margin: 8px;
	padding: 0px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 9px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: 'tnum', 'tnum';
	font-feature-settings: 'tnum', 'tnum';
	position: relative;
	display: inline-block;
	color: unset;
	line-height: 1;
}

.ant-badge-custom-count {
	z-index: 10;
	min-width: 16px;
	height: 16px;
	padding: 2px;
	color: #fff;
	font-weight: normal;
	font-size: 11px;
	line-height: 11px;
	white-space: nowrap;
	text-align: center;
	background: rgba(82, 196, 26, 0.65);
	border-radius: 8px;
	box-shadow: 0 0 0 1px #fff;
}

.decocontent {
	margin-left: 5px;
	margin-right: 5px;
	overflow: auto;
	width: 220px;
}
