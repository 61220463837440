.search_toolbar {
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.12);    
}

.bd-scroll .h-900 {
    height: 900px !important;
}

.list-description {
    position: absolute;
    top: 13px;
    right: 2rem;
}
.list-description.on {
    color: #ff8e23;
}
.list-description.off {
    color: #FFFFFF;
}
.pzNavigator {
    position: relative;
    padding: 0 20px;
    padding-bottom: 5px;
}
.pzNav {
    padding-left: inherit;
    padding-right: inherit;
    position: absolute;
    right: 0;
}
.pzNav .color {
    background-color: #5292B1 !important;
}
.pz-title {
    font-size: 20px;
    font-weight: 500;
    padding: 1.2rem 0 !important;
    color: #ffffff !important;
}

.pz-solve-direction {
    padding: 1rem 2rem !important;
}

.puzzle-msg {
    margin: 0 0 !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}

/* carousel ------------------------------ */
.carousel-header {
    padding: 2rem 0 2rem !important;
}

.clue {
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

.clue-text {
    font-weight: 700;
    font-size: 13px;
}

.clue_textarea {
    border: 0;
    width: 100%;
    height: auto;
    resize: none;
    font-size: 15px;
    line-height: 1.42857;
}

.correct-mark {
    margin-top: 10px;
}
.correct-mark span {
    position: absolute;
    left: 0;
}

.input-answer-box {
    margin-left: 35px;
}

#board {
    z-index: 1;
    width: 100%;
    overflow: hidden;
}

#across_container ul li,
#down_container ul li,
#clue_container ul li {
    list-style-type: none;
    position: relative;
    overflow: hidden; }

.clue_hover {
    background: rgba(255, 87, 0, 0.5);
}

.clue_hover > a,
.clue_highlight > a {
    color:#de3105 !important;
    font-weight: 500 !important
}

.clue_highlight,
.clue_highlight:hover {
    background: rgba(121, 93, 117, 0.5);
}

#across_list, #down_list {
    width: 100%;
    max-height: 450px;
    overflow-y: scroll; }

.tile_animator {
    display: block;
    position: absolute;
    border-radius: 100%;
    transform: scale(0); }

.tile_animator.base {
    background: #4f52bb;
}
.tile_animator.answer {
    background: #10bf50;
}
.tile_animator.wrong {
    background: #FF0000;
}
.tile_animator.min {
    background: #d9a771;
}

.color-none {
    background-color: #C7C7C7 !important;
}
.color-correct {
    background-color: #00b047 !important;
}
.color-wrong {
    background-color: #ff4439 !important;
}

.tile_animator.animate {
    animation: ripel 0.7s ease-in; }

@keyframes ripel {
    100% {opacity: 0; transform: scale(2.5);}
}

.ink {
    display: block;
    position: absolute;
    background: #c5cae9;
    border-radius: 100%;
    transform: scale(0); }

.ink.animate {
    animation: ripel 0.5s linear; }

#puzzle_answer {
    -webkit-ime-mode: active;
    -moz-ime-mode: active;
    -ms-ime-mode: active;
    ime-mode: active; }

#dragger {
    -webkit-transition: all 300ms cubic-bezier(0.085, 0.385, 0.420, 0.975);
    -moz-transition: all 300ms cubic-bezier(0.085, 0.385, 0.420, 0.975);
    -ms-transition: all 300ms cubic-bezier(0.085, 0.385, 0.420, 0.975);
    -o-transition: all 300ms cubic-bezier(0.085, 0.385, 0.420, 0.975);
    transition: all 300ms cubic-bezier(0.085, 0.385, 0.420, 0.975);
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    padding: 0;
    background-color: #444444;
}

#dragger canvas {
    position: absolute;
    top: 0;
    left: 0;
}

#key_interceptor {
    position: absolute;
    font-size: 1px;
    opacity: 0;
    left: -100px;
    top: -100px;
    -webkit-user-modify: read-write-plaintext-only; }

#key_interceptor:focus {
    -webkit-user-modify: read-write-plaintext-only; }

.card .clue-block {
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.clue_list_wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
}

.input_answer {
    margin-top: 15px !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.game_view {
    padding: 1rem !important;
}

.section-title {
    font-weight: 500 !important;
}

.remainClueInfo {
    position: absolute !important;
    right: 0;
    top: 0;
    margin-top: 11px !important;
    margin-right: 10px !important;
    font-size: 13px !important;
}

.vs-hidden {
    visibility: hidden !important;
}

.hint-mark {
    border: none;
    border-radius: 5px;
    position: relative;
    margin: 10px 8px 10px 15px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow,transform;
    -webkit-transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    background: transparent;
    padding: 4px 8px !important;
    background-color: #f37900;
    color: #ffffff;
}

.lq-circle-btn-bgcolor {
    background-color: #607d8b !important;
}

.crossed-text,
.crossed a {
    text-decoration: line-through !important;
}

.modal-title-padding-16 {
    padding: 1.6rem !important;
}

.modal-backdrop {
    position: relative !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 20rem auto !important;
    }
}