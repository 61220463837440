.natmal-color {
  color: #4d4d4d;
}

.primary-color-dic,
.primary-color-icon-dic {
  background-color: #e87347 !important;
  color: #fff !important;
}

.second-color-dic,
.second-color-icon-dic {
  background-color: #d9bc99 !important;
  color: #fff !important;
}

.third-color-dic,
.third-color-icon-dic {
  background-color: #a99172 !important;
  color: #fff !important;
}

.primary-color-etest,
.primary-color-icon-reading {
  background-color: #9fc22f !important;
  color: #fff !important;
}

.primary-btn-color-etest {
  background-color: #77a351 !important;
  color: #fff !important;
}

.primary-color-puzzle,
.primary-color-icon-maze {
  background-color: #037be3 !important;
  color: #fff !important;
}

.primary-color-analysis,
.primary-color-icon-analysis {
  background-color: #8259a7 !important;
  color: #fff !important;
}

.primary-color-english,
.primary-color-icon-english {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.primary-color-service,
.primary-color-icon-service {
  background-color: #364a84 !important;
  color: #fff !important;
}

.primary-color-icon-dic,
.primary-color-icon-reading,
.primary-color-icon-maze,
.primary-color-icon-analysis,
.primary-color-icon-english,
.primary-color-icon-service {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset !important;
}

.bg-color-white {
  background-color: #ffffff;
}

.container-popup-l02 {
  position: absolute;
  top: 12%;
  left: 2%;
  z-index: 9999;
  box-shadow: 0 5px 5px 0 rgba(34, 36, 38, 0.4);
  background-color: #ffffff;
  border: solid 2px #9a9a9a;
}

.container-popup-l02 .btn {
  padding: 10px;
  margin: 10px;
  font-size: 1em;
  background-color: black !important;
  color: white !important;
}

.container-popup-l02 .btn:hover {
  background-color: white !important;
  color: black !important;
}

.container-popup-l05 {
  position: absolute;
  top: 12%;
  left: 5%;
  z-index: 9999;
  box-shadow: 0 5px 5px 0 rgba(34, 36, 38, 0.4);
  background-color: #ffffff;
  border: solid 2px #9a9a9a;
}

.container-popup-l15 {
  position: absolute;
  top: 13%;
  left: 15%;
  z-index: 9999;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.3);
  background-color: #ffffff;
}

.container-popup-l35 {
  position: absolute;
  top: 17%;
  left: 35%;
  z-index: 9999;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.3);
  background-color: #ffffff;
}

.container-popup-l55 {
  position: absolute;
  top: 17%;
  left: 55%;
  z-index: 9999;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.3);
  background-color: #ffffff;
}

.container-login-popup {
  position: absolute;
  top: 17%;
  left: 35%;
  z-index: 9999;
  border: 10px solid;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.3);
  background-color: #ffffff;
}

.main-menu-floating-label {
  position: absolute;
  z-index: 100;
  padding: 0.2rem !important;
  top: 0.3rem !important;
  left: 70% !important;
  font-size: 0.5rem !important;
  margin: 0 0 0 -1.5em !important;
}

.main-title-bar-bg {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.subtit_main {
  font-size: 35px;
}
.subtit_t2_dark {
  font-size: 20px;
  color: #252525;
  font-weight: 700;
}
.subtit_t3_dark {
  color: #444444;
  font-size: 1.3rem;
  line-height: 2rem;
}
.subtit_t2_white {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
}
.subtit_t3_white {
  color: #ffffff;
  font-size: 1.3rem;
  line-height: 2rem;
}

.sentence-quick-btn {
  color: #fbb735;
  font-weight: 500;
  border: 1px solid #fbb735;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}
.sentence-quick-btn:hover {
  color: #ffffff;
  background-color: #f47820;
}

.sentence-quick-btn_1 {
  background-color: #f5f5f5;
  width: 250px;
  font-size: 1.5rem;
  color: #636d92;
  font-weight: 500;
  border: 1px solid #636d92;
  padding: 1rem 2rem;
  cursor: pointer;
}
.sentence-quick-btn_1:hover {
  color: #ffffff;
  background-color: #636d92;
}

.sentence-quick-btn_2 {
  background-color: #e0e1e2;
  width: 250px;
  font-size: 1.5rem;
  color: #636d92;
  font-weight: 500;
  border: 1px solid #636d92;
  padding: 1rem 2rem;
  cursor: pointer;
}
.sentence-quick-btn_2:hover {
  color: #ffffff;
  background-color: #636d92;
}

.sentence_text_highlight {
  color: #f37603 !important;
  font-weight: 500 !important;
}

.sentence-example-btn {
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.2rem 1.5rem;
  color: #5a6774;
  border: solid 1px #5a6774;
  transition-duration: 0.4s;
}
.sentence-example-btn:hover {
  background: #5a6774;
  color: #ffffff;
}

.sentence-example-btn_1 {
  width: 150px;
  background-color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.2rem 1.5rem;
  color: #508fcf;
  border: solid 1px #508fcf;
  transition-duration: 0.4s;
}
.sentence-example-btn_1:hover {
  background: #508fcf;
  color: #ffffff;
}

.puzzle-recommand-btn {
  font-size: 1.3rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #364e6e;
  border: solid 1px #364e6e;
  transition-duration: 0.4s;
}
.puzzle-recommand-btn:hover {
  background: #364e6e;
  color: #ffffff;
}

.lq-btn-01 {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #00bcd4;
  border: solid 1px #00bcd4;
  transition-duration: 0.4s;
}
.lq-btn-01:hover {
  background: #00bcd4;
  color: #ffffff;
}

.lq-btn-02 {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #fe9800;
  border: solid 1px #fe9800;
  transition-duration: 0.4s;
}
.lq-btn-02:hover {
  background: #fe9800;
  color: #ffffff;
}

.lq-btn-03 {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #4caf50;
  border: solid 1px #4caf50;
  transition-duration: 0.4s;
}
.lq-btn-03:hover {
  background: #4caf50;
  color: #ffffff;
}

.lq-btn-04 {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #f44336;
  border: solid 1px #f44336;
  transition-duration: 0.4s;
}
.lq-btn-04:hover {
  background: #f44336;
  color: #ffffff;
}

.lq-btn-sub-01 {
  background: #e0e1e2;
  width: 200px;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #5f7d8b;
  border: solid 1px #5f7d8b;
  transition-duration: 0.4s;
}
.lq-btn-sub-01:hover {
  background: #5f7d8b;
  color: #ffffff;
}

.lq-btn-sub-02 {
  background: #ffffff;
  width: 200px;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #5f7d8b;
  border: solid 1px #5f7d8b;
  transition-duration: 0.4s;
}
.lq-btn-sub-02:hover {
  background: #5f7d8b;
  color: #ffffff;
}

.lq-btn-sub-03 {
  background: #e0e1e2;
  width: 250px;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #5f7d8b;
  border: solid 1px #5f7d8b;
  transition-duration: 0.4s;
}
.lq-btn-sub-03:hover {
  background: #5f7d8b;
  color: #ffffff;
}

.lq-btn-sub-04 {
  background: #f5f5f5;
  width: 250px;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal !important;
  cursor: pointer;
  padding: 1rem 1.8rem;
  color: #5f7d8b;
  border: solid 1px #5f7d8b;
  transition-duration: 0.4s;
}
.lq-btn-sub-04:hover {
  background: #5f7d8b;
  color: #ffffff;
}

.link-text-to-external {
  color: #012473 !important;
  text-decoration: underline;
}
.link-text-to-external:hover {
  font-weight: 600;
}

.img-size-40p {
  width: 40% !important;
}

.ul-start-10 {
  padding-inline-start: 1rem !important;
}

.sentence-ani-img-shadow {
  bottom: -59rem;
  left: 0;
  z-index: 5;
}
.sentence-ani-img01_1 {
  top: 80px;
  left: 70px;
  z-index: 10;
}
.sentence-ani-img01_2 {
  top: 30px;
  left: 360px;
  z-index: 10;
}
.sentence-ani-img01_3 {
  top: 190px;
  left: 185px;
  z-index: 11;
}
.sentence-ani-img01_4 {
  top: 300px;
  left: 440px;
  z-index: 11;
}

.sentence-ani-img02_1 {
  top: 0px;
  left: 100px;
  z-index: 11;
}

.sentence-ani-img03 {
  top: 150px;
  left: 10px;
  z-index: 11;
}

.btnGoToSentence {
  font-weight: 500;
  color: #ffffff !important;
  background-color: #636d92 !important;
}

.btnPuzzleCategoryBack {
  font-weight: 500;
  color: #ffffff !important;
  background-color: #506a8f !important;
}

.puzzle-icon-size-60 {
  width: 60px !important;
  height: 60px !important;
}

.main-puzzle-search {
  padding: 3rem 0;
  background-color: #364e6f;
  color: white;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    inset 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    inset 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.puzzle-appbar-bg {
  background-color: #4e668c !important;
}

.div-v-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-circle:before {
  background-color: #f37900 !important;
}

.img-wrap {
  width: 100%;
  position: relative;
}
.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
.img-text-title {
  width: 55%;
  position: absolute;
  top: 15%;
  left: 35%;
  line-height: 1.7rem;
}
.img-text-desc {
  position: absolute;
  top: 20%;
  left: 35%;
}

.padding-all-3 {
  padding: 3rem !important;
}

.margin-bottom-6 {
  margin-bottom: 0.6rem !important;
}

.max-w-450 {
  max-width: 450px !important;
}
.max-w-500 {
  max-width: 500px !important;
}

.min-h-100 {
  min-height: 100px !important;
}
.min-h-250 {
  min-height: 250px !important;
}
.min-h-700 {
  min-height: 700px !important;
}

.input-max-w-80 {
  max-width: 80px !important;
}

.input-max-w-120px {
  max-width: 120px !important;
}

.mypage-login-bg-color {
  background-color: #d0d0d0 !important;
}
.mypage-login-font-color {
  color: #5a5a5a !important;
}

.link-gray {
  color: #666 !important;
}

.my-t-1 {
  margin-top: 0.1rem !important;
}

.my-t-2 {
  margin-top: 0.2rem !important;
}

.my-t-4 {
  margin-top: 0.4rem !important;
}

.natmal-h1 {
  font-size: 2.4rem !important;
}
.natmal-h2 {
  font-size: 2.1rem !important;
}
.natmal-h3 {
  font-size: 1.8rem !important;
}
.natmal-h4 {
  font-size: 1.6rem !important;
}
.natmal-h5 {
  font-size: 1.4rem !important;
}
.natmal-h6 {
  font-size: 1.2rem !important;
}

.text-highlight-red {
  color: red;
}

.text-highlight-orangered {
  color: orangered;
}

.text-highlight-blue {
  color: #146ece;
}

.natmal-bg-lightgray {
  background-color: #d4d4d4;
}

.line-height-12 {
  line-height: 1.2rem !important;
}
.line-height-15 {
  line-height: 1.5rem !important;
}
.line-height-16 {
  line-height: 1.6rem !important;
}
.line-height-17 {
  line-height: 1.7rem !important;
}
.line-height-20 {
  line-height: 2rem !important;
}

/* select */
.natmal-select {
  background-color: #eee;
  position: relative;
}
.natmal-select select {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  border: 1px solid #ddd;
  background-color: #efefef;
  font-size: 1.3rem;
}

/* input */
.natmal-input {
  vertical-align: middle;
  border: 1px solid #ddd;
  width: 100%;
  height: 40px;
  border-radius: 1px;
  background-color: #efefef;
  padding: 5px 15px;
  font-size: 1.3rem;
}

.natmal-input-wrap-with {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  border-radius: 1px;
}

.natmal-input-with {
  border: none;
  background-color: #efefef;
  flex: 1;
  padding: 5px 15px;
  font-size: 1.3rem;
}

/* table */
table .col-1 {
  width: 10%;
}
table .col-2 {
  width: 20%;
}

.natmal-table-bordered {
  border-top: transparent;
  border-bottom: 1px dashed #dee2e6;
}

.natmal-table-bordered td,
.table-bordered th {
  border-top: transparent;
  border-bottom: 1px dashed #dee2e6;
}

/* textarea */
.natmal-textarea {
  vertical-align: middle;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 1px;
  background-color: #efefef;
  padding: 5px 15px;
  font-size: 1.3rem;
}

/*button*/
.natmal-btn-white-xs {
  border: 1px solid #4d4d4d;
  background: #fff;
  color: #4d4d4d;
  padding: 0px 20px;
  font-size: 1.2rem;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.natmal-btn-white-xs:hover {
  border: 1px solid #4d4d4d;
  background: #4d4d4d;
  color: #fff;
}

.natmal-btn-dark-sm {
  display: inline-block;
  background: #4d4d4d;
  color: #fff;
  padding: 5px 15px;
  font-size: 1.3rem;
}
.natmal-btn-dark-sm:hover {
  background: #959595;
  color: #fff;
}
.natmal-btn-dark-sm:visited {
  color: #fff;
}

.btn.btn-raised.natmal-btn-white {
  background-color: #fff;
  color: #333;
  border: solid 2px #4d4d4d;
  margin: 0 10px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.btn.btn-raised.natmal-btn-white:hover {
  background-color: #666;
  color: rgba(255, 255, 255, 0.84);
  border: solid 2px #4d4d4d;
  margin: 0 10px;
}

.btn.btn-raised.natmal-btn-dark {
  background-color: #4d4d4d;
  color: rgba(255, 255, 255, 0.84);
  border: solid 2px #4d4d4d;
  margin: 0 10px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.btn.btn-raised.natmal-btn-dark:hover {
  background-color: #fff;
  color: #4d4d4d;
  border: solid 2px #4d4d4d;
  margin: 0 10px;
}

.natmal-btn-form {
  display: inline-block;
  background: #686868;
  color: #fff;
  padding: 5px 15px;
  font-size: 1.3rem;
  height: 40px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #646464;
}
.natmal-btn-form:hover {
  background: #fff;
  color: #4d4d4d;
  border: 1px solid #4d4d4d;
}

/* star rating */
.natmal-rating {
  background: #fff;
}

.natmal-rating-radio {
  position: fixed;
  top: 0;
  right: 100%;
}

.natmal-rating-label {
  font-size: 1em;
  padding: 0.1em;
  margin: 0;
  float: left;
  cursor: pointer;
}

.natmal-rating-radio:checked ~ input + .natmal-rating-label {
  background: none;
  color: #aaa;
}

input + .natmal-rating-label {
  background: #fff;
  color: orange;
  margin: 0 0 1em 0;
}

input + .natmal-rating-label:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

input:checked + .natmal-rating-label {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* modal */
.natmal-modal-footer {
  border-top: 1px solid #bebebe;
  margin-top: 5px;
  padding: 10px;
}

.natmal-table-sm {
  border-top: 4px double #bfbfbf;
  border-bottom: 4px double #bfbfbf;
}

/* pagination */
.natmal-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 1.2rem;
  margin: 10px 0;
}
.natmal-pagination .natmal-page-item .natmal-page-link {
  margin: 2px;
  padding: 5px 10px;
}

/* card-dark*/
.natmal-ms-bg-gray {
  position: relative;
}

.natmal-ms-bg-gray:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #666;
  z-index: 0;
}
.natmal-ms-bg-gray .container {
  position: relative;
  z-index: 1;
}

.card.card-dark {
  border-bottom: solid 3px #666;
  border-radius: 3px;
}
.card.card-dark .card-header,
.card.card-dark .card-footer {
  background-color: #666;
  color: #f5f5f5;
}

.natmal-vertical-dashed-line {
  border-right: 1px dashed #c7c7c7;
}

.natmal-label {
  font-size: 1.3rem;
  color: #4d4d4d;
}

.badge {
  /*padding: 0.55em 0.75em 0.6em 0.75em;*/
  font-size: 75%;
}
.badge.badge-pill {
  padding-right: 1.25em;
  padding-left: 1.25em;
}
.badge.badge-top-left {
  top: 10px;
  left: -15px;
}
.badge.badge-top-right {
  top: 8px;
  right: -7px;
}

.list-atag {
  color: #333 !important;
}

@media (max-width: 800px) {
  .btn.btn-raised.natmal-btn-dark {
    padding: 5px 25px;
    font-size: 1.3rem;
  }

  .btn.btn-raised.natmal-btn-white {
    padding: 5px 25px;
    font-size: 1.3rem;
  }

  .btn.btn-sm {
    padding: 5px 12px;
  }

  .natmal-vertical-dashed-line {
    border: 0;
  }
}

@media screen and (max-width: 768px) {
  .show-pc {
    display: none !important;
  }
}

@media all and (min-width: 768px) {
  .btn-mobile-dic {
    display: none;
  }

  .show-mobile {
    display: none;
  }
}
