.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 10px 0;
}

/* 24/2/14 antd의 모든 스타일을 덮어씌우는 중 어디에서 쓰이는지 확인할 수 없음 */
/* .ant-btn:hover,
.ant-btn:focus {
  color: #90cf77;
  background-color: #fff;
  border-color: #90cf77;
} */
