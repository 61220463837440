/*
.footer-menu li.nav-item{
  font-size: 10pt;
  line-height: 3.3em;
}

.footer-menu{
  text-align: center;
}
*/
.btn-back-top {
	z-index: 3000 !important;
}

.ms-footer {
	background-color: #444444 !important;
}

.ms-footer a {
	color: #bdbdbd !important;
}
.ms-footer a,
.ms-footer a:hover,
.ms-footer a:focus {
	color: rgba(255, 255, 255, 0.84) !important;
}

.ms-configurator-btn {
	right: 0px !important;
}

.sns-icons {
	gap: 20px;
}

.menu-item4-footer {
	position: relative;
	right: 4px;
	width: 30px !important;
	height: 30px !important;
	background-image: url('/assets/imgs/home/X_logo_footer.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	vertical-align: middle;
	cursor: pointer;
	/* overflow: visible !important; */
}

/* .menu-item4-footer:hover {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
}

.menu-item4-footer.active {
	background-image: url('/assets/imgs/home/X_logo_hover.png');
} */
