.ant-collapse > .ant-collapse-item {
	border-bottom: 0px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	top: 38% !important;
}

.ant-collapse-select {
	border-right: 3px solid #1890ff;
	color: #fff;
	background: #1890ff;
}
.menucontent {
	position: fixed;
	top: 0px; /*Set top value to HeightOfTopFrameDiv*/
	margin-top: 5px;
	margin-left: 0px;
	margin-right: 0px;
	bottom: 0px; /*Set bottom value to HeightOfBottomFrameDiv*/
	overflow: auto;
	width: 245px;
	z-index: +1;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	position: relative;
	padding: 0px;
	color: #000 !important;
	line-height: 16px !important;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
	padding-left: 0px;
}

.ant-collapse-content > .ant-collapse-content-box {
	padding: 0px;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-top: 0px;
}

/*
.ant-carousel {
	padding: 5px;
	background: #08979c;
}

.ant-carousel .slick-slide {
	text-align: center;
	line-height: 70px;
	background: #08979c;
	overflow: hidden;
}

.ant-carousel .slick-dots li button {
	background: cyan;
}

.ant-carousel .slick-dots li.slick-active button {
	background: lightskyblue;
}
*/

.ant-carousel .slick-slide {
	text-align: center;
	line-height: 70px;
	overflow: hidden;
}
